export const API_ROOT_URL = 'https://api.covid19india.org/v4/min';

export const LOCALE_SHORTHANDS = {
  english: 'en-US',
};

export const STATISTIC_DEFINITIONS = {
  confirmed: {
    displayName: 'confirmed',
    color: '#ff073a',
    format: 'int',
    options: {key: 'confirmed'},
  },
  active: {
    displayName: 'active',
    color: '#007bff',
    format: 'int',
    options: {key: 'active'},
    // hideDelta: true,
  },
  recovered: {
    displayName: 'recovered',
    color: '#28a745',
    format: 'int',
    options: {key: 'recovered'},
  },
  deceased: {
    displayName: 'deceased',
    color: '#6c757d',
    format: 'int',
    options: {key: 'deceased'},
  },
  vaccinated: {
    displayName: 'vaccine doses',
    color: '#28a745',
    format: 'int',
    options: {key: 'dosesAdministered'},
  },
  other: {
    displayName: 'other',
    format: 'int',
    options: {key: 'other'},
  },
  tested: {
    displayName: 'tested',
    color: '#4b1eaa',
    format: 'short',
    options: {key: 'tested'},
  },
  activeRatio: {
    displayName: 'active rate',
    format: '%',
    options: {
      key: 'active',
      normalizeByKey: 'confirmed',
      multiplyFactor: 100,
    },
    hideDelta: true,
  },
  recoveryRatio: {
    displayName: 'recovery rate',
    format: '%',
    options: {
      key: 'recovered',
      normalizeByKey: 'confirmed',
      multiplyFactor: 100,
    },
    hideDelta: true,
  },
  cfr: {
    displayName: 'case fatality rate',
    format: '%',
    options: {
      key: 'deceased',
      normalizeByKey: 'confirmed',
      multiplyFactor: 100,
    },
    hideDelta: true,
  },
  dbp: {
    displayName: 'deceased by population',
    format: '%',
    options: {
      key: 'deceased',
      normalizeByKey: 'population',
      multiplyFactor: 100,
    },
    hideDelta: true,
  },
  tpr: {
    displayName: 'test positivity rate',
    color: '#fd7e14',
    format: '%',
    options: {
      key: 'confirmed',
      normalizeByKey: 'tested',
      multiplyFactor: 100,
    },
    hideDelta: true,
  },
  population: {
    displayName: 'population',
    format: 'short',
    options: {key: 'population'},
    hideDelta: true,
  },
  confirmedByPop: {
    displayName: 'confirmed by population',
    color: '#ff073a',
    format: '%',
    options: {
      key: 'confirmed',
      normalizeByKey: 'population',
      multiplyFactor: 100,
    },
    hideDelta: true,
  },
};

const definitions = Object.keys(STATISTIC_DEFINITIONS).reduce(
  (acc, statistic) => {
    const {options, ...config} = STATISTIC_DEFINITIONS[statistic];
    acc.options[statistic] = options;
    acc.configs[statistic] = config;
    return acc;
  },
  {options: {}, configs: {}}
);

export const STATISTIC_CONFIGS = definitions.configs;
export const STATISTIC_OPTIONS = definitions.options;

export const PER_MILLION_OPTIONS = {
  normalizeByKey: 'population',
  multiplyFactor: 1e6,
};

export const NAN_STATISTICS = [
  'tested',
  'tpr',
  'population',
  'active',
  'recovered',
];

export const PRIMARY_STATISTICS = [
  'confirmed',
  'active',
  'recovered',
  'deceased',
  'vaccinated',
];

export const TABLE_STATISTICS = [...PRIMARY_STATISTICS, 'tested'];

export const TABLE_STATISTICS_EXPANDED = Object.keys(STATISTIC_DEFINITIONS);

export const TIMESERIES_STATISTICS = [...PRIMARY_STATISTICS, 'tested'];

export const UPDATES_COUNT = 5;

export const DISTRICT_TABLE_COUNT = 57;

export const D3_TRANSITION_DURATION = 300;

export const MINIGRAPH_LOOKBACK_DAYS = 20;

export const TESTED_LOOKBACK_DAYS = 7;

export const UNASSIGNED_STATE_CODE = 'UN';

export const UNKNOWN_DISTRICT_KEY = 'Unknown';

export const ISO_DATE_REGEX = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/g;

export const INDIA_ISO_SUFFIX = 'T00:00:00+05:30';

export const SPRING_CONFIG_NUMBERS = {clamp: true, precision: 1};

export const TIMESERIES_CHART_TYPES = {
  total: 'Cumulative',
  delta: 'Daily',
};

export const TIMESERIES_LOOKBACKS = {
  BEGINNING: 'Beginning',
  THREE_MONTHS: '3 Months',
  MONTH: '1 Month',
};

export const MAP_VIZS = {
  CHOROPLETH: 0,
  BUBBLES: 1,
};

export const MAP_VIEWS = {
  STATES: 0,
  COUNTY: 1,
};

export const MAP_TYPES = {
  COUNTRY: 0,
  STATE: 1,
};

export const MAPS_DIR =
  // process.env.NODE_ENV === 'production' ? '/mini_maps' : '/projected_maps';
  '/mini_maps';

export const MAP_META = {
  AL: {
    geoDataFile: `${MAPS_DIR}/AL-01-alabama-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  AK: {
    geoDataFile: `${MAPS_DIR}/AK-02-alaska-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  DC: {
    geoDataFile: `${MAPS_DIR}/DC-11-columbia-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  AZ: {
    geoDataFile: `${MAPS_DIR}/AZ-04-arizona-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  AR: {
    geoDataFile: `${MAPS_DIR}/AR-05-arkansas-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  CA: {
    geoDataFile: `${MAPS_DIR}/CA-06-california-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  CO: {
    geoDataFile: `${MAPS_DIR}/CO-08-colorado-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  CT: {
    geoDataFile: `${MAPS_DIR}/CT-09-connecticut-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  DE: {
    geoDataFile: `${MAPS_DIR}/DE-10-delaware-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  FL: {
    geoDataFile: `${MAPS_DIR}/FL-12-florida-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  GA: {
    geoDataFile: `${MAPS_DIR}/GA-13-georgia-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  HI: {
    geoDataFile: `${MAPS_DIR}/HI-15-hawaii-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  ID: {
    geoDataFile: `${MAPS_DIR}/ID-16-idaho-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  IL: {
    geoDataFile: `${MAPS_DIR}/IL-17-illinois-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  IN: {
    geoDataFile: `${MAPS_DIR}/IN-18-indiana-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  IA: {
    geoDataFile: `${MAPS_DIR}/IA-19-iowa-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  KS: {
    geoDataFile: `${MAPS_DIR}/KS-20-kansas-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  KY: {
    geoDataFile: `${MAPS_DIR}/KY-21-kentucky-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  LA: {
    geoDataFile: `${MAPS_DIR}/LA-22-louisiana-parishes.json`,
    mapType: MAP_TYPES.STATE,
  },
  ME: {
    geoDataFile: `${MAPS_DIR}/ME-23-maine-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  MD: {
    geoDataFile: `${MAPS_DIR}/MD-24-maryland-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  MA: {
    geoDataFile: `${MAPS_DIR}/MA-25-massachusetts-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  MI: {
    geoDataFile: `${MAPS_DIR}/MI-26-michigan-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  MN: {
    geoDataFile: `${MAPS_DIR}/MN-27-minnesota-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  MS: {
    geoDataFile: `${MAPS_DIR}/MS-28-mississippi-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  MO: {
    geoDataFile: `${MAPS_DIR}/MO-29-missouri-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  MT: {
    geoDataFile: `${MAPS_DIR}/MT-30-montana-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  NE: {
    geoDataFile: `${MAPS_DIR}/NE-31-nebraska-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  NV: {
    geoDataFile: `${MAPS_DIR}/NV-32-nevada-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  NH: {
    geoDataFile: `${MAPS_DIR}/NH-33-new-hampshire-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  NJ: {
    geoDataFile: `${MAPS_DIR}/NJ-34-new-jersey-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  NM: {
    geoDataFile: `${MAPS_DIR}/NM-35-new-mexico-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  NY: {
    geoDataFile: `${MAPS_DIR}/NY-36-new-york-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  NC: {
    geoDataFile: `${MAPS_DIR}/NC-37-north-carolina-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  ND: {
    geoDataFile: `${MAPS_DIR}/ND-38-north-dakota-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  OH: {
    geoDataFile: `${MAPS_DIR}/OH-39-ohio-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  OK: {
    geoDataFile: `${MAPS_DIR}/OK-40-oklahoma-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  OR: {
    geoDataFile: `${MAPS_DIR}/OR-41-oregon-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  PA: {
    geoDataFile: `${MAPS_DIR}/PA-42-pennsylvania-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  RI: {
    geoDataFile: `${MAPS_DIR}/RI-44-rhode-island-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  SC: {
    geoDataFile: `${MAPS_DIR}/SC-45-south-carolina-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  SD: {
    geoDataFile: `${MAPS_DIR}/SD-46-south-dakota-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  TN: {
    geoDataFile: `${MAPS_DIR}/TN-47-tennessee-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  TX: {
    geoDataFile: `${MAPS_DIR}/TX-48-texas-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  UT: {
    geoDataFile: `${MAPS_DIR}/UT-49-utah-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  VT: {
    geoDataFile: `${MAPS_DIR}/VT-50-vermont-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  VA: {
    geoDataFile: `${MAPS_DIR}/VA-51-virginia-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  WA: {
    geoDataFile: `${MAPS_DIR}/WA-53-washington-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  WV: {
    geoDataFile: `${MAPS_DIR}/WV-54-west-virginia-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  WI: {
    geoDataFile: `${MAPS_DIR}/WI-55-wisconsin-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  WY: {
    geoDataFile: `${MAPS_DIR}/WY-56-wyoming-counties.json`,
    mapType: MAP_TYPES.STATE,
  },
  PR: {
    geoDataFile: `${MAPS_DIR}/PR-72-puerto-rico-municipios.json`,
    mapType: MAP_TYPES.STATE,
  },
  TT: {
    geoDataFile: `${MAPS_DIR}/usa.json`,
    mapType: MAP_TYPES.COUNTRY,
  },
  // TT: {
  //   geoDataFile: `${MAPS_DIR}/states-10m.json`,
  //   mapType: MAP_TYPES.COUNTRY,
  // },
  // State: {
  //   geoDataFile: `${MAPS_DIR}/counties-10m.json`,
  //   mapType: MAP_TYPES.STATE,
  // },
};

export const MAP_LEGEND_HEIGHT = 50;

export const STATE_NAMES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  DC: 'District of Columbia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  GU: 'Guam',
  MP: 'Northern Mariana Islands',
  PR: 'Puerto Rico',
  VI: 'Virgin Islands',
  TT: 'United States',
};

const stateCodes = [];
const stateCodesMap = {};
Object.keys(STATE_NAMES).map((key, index) => {
  stateCodesMap[STATE_NAMES[key]] = key;
  stateCodes.push({code: key, name: STATE_NAMES[key]});
  return null;
});
export const STATE_CODES = stateCodesMap;
export const STATE_CODES_ARRAY = stateCodes;
